import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  Linking,
  useColorScheme,
} from "react-native";
import { useState, useEffect } from "react";
// import { GestureHandlerRootView } from "react-native-gesture-handler";
import "react-native-reanimated";
import { MotiView } from "moti";
import { AlarmSound } from "./components/Sound";
import { FontAwesome5 } from "@expo/vector-icons";
import { SafeAreaProvider } from "react-native-safe-area-context";

export default function App() {
  const [settingsVisible, setSettingsVisible] = useState(false);
  const colorScheme = useColorScheme();
  const [timerMode, setTimerMode] = useState("pomod"); // options: pomod, short, long
  const [mute, setMute] = useState(false);
  const [pomoLength, setPomoLength] = useState(25);
  const [shortLength, setShortLength] = useState(5);
  const [longLength, setLongLength] = useState(15);
  const [accentColor, setAccentColor] = useState("default"); // options: default, blue, purple
  const [secondsLeft, setSecondsLeft] = useState(pomoLength * 60);
  const [isActive, setIsActive] = useState(false);
  const [buttonText, setButtonText] = useState("START");
  // console.log("colorScheme", colorScheme);
  const themeColor = colorScheme === "light" ? "white" : "black";
  const textColor = colorScheme === "light" ? "black" : "white";
  const statusBarColor = colorScheme === "light" ? "dark" : "light";

  useEffect(() => {
    // console.log("mute", mute);
    if (isActive) {
      const interval = setInterval(() => {
        setSecondsLeft((secondsLeft) => secondsLeft - 1);
      }, 1000);

      if (secondsLeft === 0) {
        clearInterval(interval);
        setIsActive(false);
        setButtonText("");
        mute ? console.log("ok") : AlarmSound();
      }

      return () => clearInterval(interval);
    }
  }, [isActive, secondsLeft]);

  let timesUpMsg = timerMode === "pomod" ? "time for a break" : "back to work!";

  const formatTimeLeft = (seconds) => {
    return `${Math.floor(seconds / 60)}:${
      seconds % 60 > 9 ? seconds % 60 : "0" + (seconds % 60)
    }`;
  };

  let timerFormat = formatTimeLeft(secondsLeft);

  let timeText = timerFormat === "0:00" ? timesUpMsg : timerFormat;
  let textSize = timerFormat === "0:00" ? 23 : 77;

  const toggleSettingsVisibility = (event) => {
    setSettingsVisible(!settingsVisible);
  };

  const calcPercentage = () => {
    if (timerMode === "pomod") {
      return (secondsLeft / (pomoLength * 60)) * 100;
    }
    if (timerMode === "short") {
      return (secondsLeft / (shortLength * 60)) * 100;
    }
    if (timerMode === "long") {
      return (secondsLeft / (longLength * 60)) * 100;
    }
  };

  const handleButtonClick = () => {
    // console.log("timerMode", timerMode);
    if (timerMode === "short" && secondsLeft === 0) {
      setIsActive(isActive);
      setButtonText("START");
      setTimerMode("pomod");
      setSecondsLeft(pomoLength * 60);
    } else if (timerMode === "long" && secondsLeft === 0) {
      setIsActive(isActive);
      setButtonText("START");
      setTimerMode("pomod");
      setSecondsLeft(pomoLength * 60);
    } else if (timerMode === "pomod" && secondsLeft === 0) {
      setIsActive(!isActive);
      // console.log("triggered");
      setTimerMode("short");
      setSecondsLeft(shortLength * 60);
      setButtonText("PAUSE");
    } else {
      setIsActive(!isActive);
      setButtonText(
        buttonText === "START" || buttonText === "RESUME" ? "PAUSE" : "RESUME"
      );
    }
  };

  const handleModeChange = (timerType) => {
    setTimerMode(timerType);
    setIsActive(false);
    setButtonText("START");
    switch (timerType) {
      case "short":
        setSecondsLeft(shortLength * 60);
        break;
      case "long":
        setSecondsLeft(longLength * 60);
        break;
      default:
        setSecondsLeft(pomoLength * 60);
    }
  };

  const [toolTipsActive, setToolTips] = useState(false);

  const handleToolTips = () => {
    if (toolTipsActive === true) {
      setToolTips(false);
    } else {
      setToolTips(true);
    }
  };

  const ControlType = ({ textButton, onPress }) => {
    return (
      <TouchableOpacity
        style={{
          ...styles.controlType,
          backgroundColor: timerMode === textButton ? "#abd7eb" : "black",
          borderColor: timerMode === textButton ? "#abd7eb" : "black",
        }}
        onPress={onPress}
      >
        <Text style={styles.controlText}>{textButton}</Text>
      </TouchableOpacity>
    );
  };

  const MotiCircle = ({ size, children, onPress, disabled }) => {
    return (
      <TouchableOpacity onPress={onPress} disabled={disabled}>
        <MotiView
          // from={{
          //   width: size,
          //   height: size,
          //   borderRadius: size / 2,
          // }}
          // animate={{
          //   width: size + 20,
          //   height: size + 20,
          //   borderRadius: (size + 20) / 2,
          // }}
          style={{
            width: size,
            height: size,
            borderRadius: size / 2,
            borderWidth: size / 23,
            padding: 30,
            textAlign: "center",
            borderColor: "white",
            alignItems: "center",
            justifyContent: "center",
            borderColor: "#abd7eb",
            shadowColor: "#abd7eb",
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 1,
            shadowRadius: 10,
          }}
        >
          {children}
        </MotiView>
      </TouchableOpacity>
    );
  };

  const [tipsActive, setTipsActive] = useState(false);
  const [textTips, setTextTips] = useState("place holder for tips");

  return (
    <SafeAreaProvider
      style={{ ...styles.container, backgroundColor: themeColor }}
    >
      {/* <View
        style={{
          flexDirection: "row",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        {data.map((p, i) => {
          return (
            <Donut
              key={i}
              percentage={p.percentage}
              color={p.color}
              delay={500 + 100 * i}
              max={p.max}
            />
          );
        })}
      </View> */}
      <MotiCircle
        size={300}
        onPress={() => handleButtonClick()}
        children={
          <View style={{ alignItems: "center" }}>
            <Text style={{ color: textColor, fontSize: textSize }}>
              {timeText}
            </Text>
            <Text style={{ ...styles.actionText, color: textColor }}>
              {buttonText}
            </Text>
          </View>
        }
      />
      <TouchableOpacity
        onPress={() => setMute(!mute)}
        style={{ marginTop: 20 }}
      >
        {mute ? (
          <FontAwesome5 name="volume-mute" size={24} color={textColor} />
        ) : (
          <FontAwesome5 name="volume-up" size={24} color={textColor} />
        )}
      </TouchableOpacity>
      <TouchableOpacity
        style={{
          ...styles.cornerTips,
          backgroundColor: colorScheme === "dark" ? "gray" : "#abd7eb",
          width: toolTipsActive ? 370 : 70,
        }}
        onPress={() => handleToolTips()}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: toolTipsActive ? 300 : 0,
            // borderWidth: 1,
            // borderColor: "red",
          }}
        >
          <Image
            style={styles.lightBulb}
            source={require("./assets/images/light-bulb.png")}
          />
          {toolTipsActive === true ? (
            <Text style={{ color: textColor, paddingLeft: 10 }}>
              taking rest is necessary to have better focus and become more
              productive
            </Text>
          ) : null}
        </View>
      </TouchableOpacity>
      <View style={styles.controlContainer}>
        <ControlType
          textButton="pomod"
          onPress={() => handleModeChange("pomod")}
        />
        <ControlType
          textButton="short"
          onPress={() => handleModeChange("short")}
        />
        <ControlType
          textButton="long"
          onPress={() => handleModeChange("long")}
        />
      </View>
      <TouchableOpacity
        style={styles.adsSlot}
        onPress={() => Linking.openURL("https://shawwals.com/")}
      >
        <Text style={{ color: textColor }}>Created by Shawwal © 2023</Text>
      </TouchableOpacity>
      <StatusBar style={statusBarColor} />
    </SafeAreaProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  lightBulb: {
    width: 32,
    height: 32,
  },
  cornerTips: {
    position: "absolute",
    right: 30,
    top: 70,
    // backgroundColor: "#ffbb73",
    // backgroundColor: "#636366",
    height: 70,
    borderRadius: 35,
    alignItems: "center",
    justifyContent: "center",
  },
  actionText: {
    fontSize: 29,
    paddingTop: 20,
    letterSpacing: 10,
  },
  controlContainer: {
    marginTop: 77,
    flexDirection: "row",
    justifyContent: "space-between",
    borderWidth: 1,
    borderColor: "#636366",
    backgroundColor: "#636366",
    paddingVertical: 10,
    borderRadius: 20,
  },
  controlText: {
    color: "white",
    fontSize: 15,
    fontWeight: "bold",
  },
  controlType: {
    width: 100,
    borderWidth: 1,
    borderColor: "#abd7eb",
    alignItems: "center",
    borderRadius: 20,
    marginHorizontal: 10,
    padding: 7,
    backgroundColor: "#abd7eb",
  },
  mainContainer: {
    width: 300,
    height: 300,
    borderRadius: 150,
    borderWidth: 5,
    alignItems: "center",
    justifyContent: "center",
    borderColor: "#abd7eb",
    padding: 30,
  },
  adsSlot: {
    width: "100%",
    borderTopWidth: 1,
    backgroundColor: "rgba(0, 0, 0, 0)",
    borderColor: "gray",
    alignItems: "center",
    position: "absolute",
    bottom: 20,
    padding: 20,
  },
});
