import { Audio } from "expo-av";

export const AlarmSound = async () => {
  const soundObject = new Audio.Sound();

  try {
    await soundObject.loadAsync(require("../assets/sounds/alarm-clock.mp3"));
    await soundObject.playAsync();
    // Your sound is playing!
  } catch (error) {
    // An error occurred!
    await soundObject.unloadAsync();
  }
};
